<template>
  <div>
    <el-card
      v-for="item in tableData"
      :key="item.appType"
      style="margin-bottom: 20px"
    >
      <template v-slot:header>
        <div>
          <span>{{ item.appType }}</span>
        </div>
      </template>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col
          v-for="(uItem, index) in item.userTypes"
          :key="uItem.userType"
          :span="8"
        >
          <div class="user_type_box" :style="{ color: getColor(index) }">
            <div class="type_font">{{ uItem.userType }}</div>
            <div
              class="type_font username_length"
              :style="{ background: getColor(index) }"
              @click="
                openUserNameList(
                  uItem.userNames,
                  item.appType + `[${uItem.userType}]`
                )
              "
            >
              {{ uItem.userNames.length }}
            </div>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { getUserHabitsLog } from '@/api/softVersion.js'
export default {
  data() {
    return {
      tableData: [],
      color: ['#40c9c6', '#36A3F7', '#F4516C', '#34BFA3'],
    }
  },
  created() {
    this.getData()
  },
  beforeDestroy() {},
  methods: {
    async getData() {
      let { data } = await getUserHabitsLog()

      console.log(data)
      this.tableData = data
    },
    getColor(val) {
      let index = val % 4
      return this.color[index]
    },
    openUserNameList(arr, title) {
      const h = this.$createElement

      this.$notify({
        title: title,
        duration: 0,
        message: h(
          'span',
          { style: 'color: teal' },
          arr.map((item) => {
            return h('div', { fontFamily: 'Helvetica Neue' }, item)
          })
        ),
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.user_type_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  .type_font {
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB',
      'Microsoft YaHei', '微软雅黑', Arial, sans-serif;
    font-size: 32px;
    font-weight: bold;
  }
}

.username_length {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  text-align: center;
  line-height: 80px;
  background: #660066;
  color: white;
  cursor: pointer;
}
</style>
